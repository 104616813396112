<template>
  <div class="container">

    <template>

      <b-modal class="big-screen-modal" v-model="showErrorsModal">
        <p class="title">Errors Object</p>
        <i-frame>
          <p>{{ (this.returnedError && Object.keys(this.returnedError).length > 0)
                ? this.returnedError
                : 'No errors encountered.'
              }}
          </p>
        </i-frame>
      </b-modal>

      <b-modal class="big-screen-modal" v-model="showInfoModal">
        <p class="title">Info Object</p>
        <i-frame>
          <p>{{ (this.returnedFullResponse && Object.keys(this.returnedFullResponse).length > 0)
                ? this.returnedFullResponse
                : 'Returned response object is empty.'
              }}
          </p>
        </i-frame>
      </b-modal>

      <div class="network-list--header">
      <!--
        <b-input
          size="is-medium"
          placeholder="Search client"
          v-model="gridSearchCriteria"
          icon-pack="fas"is
          icon="search"
          icon-clickable
          @icon-click="searchNetworks"
          @input="searchNetworks"
        ></b-input>
      -->
        <router-link to="/test-members-creation/create-edit">
          Create new request
          <img
            src="../assets/add_button.svg"
            alt="Plus icon inside a square"
          />
        </router-link>
      </div>

      <!--
      <b-field class="network-list--results" v-if="isSearchNetworks">
        <span>{{ networkConfigList.length }} {{ networkConfigList.length === 1 ? 'result' : 'results' }} found</span>
      </b-field>
      -->

      <b-table
        ref="gridTable"
        class="network-list--table"
        :class="{ 'is-empty': !testMemberRequestsList || testMemberRequestsList.length === 0 }"
        :data="testMemberRequestsList"
        :height="600"
        default-sort="clientName"
        sort-icon="plus"
        sticky-header
        bordered
        @sort="onSort"
      >
        <b-table-column
          sortable
          field="clientId"
          label="Client ID"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.clientId) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column
          field="planId"
          label="Plan ID"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.planId) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column
          field="zipCode"
          label="Zip Code"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.zipCode) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column
          field="memberType"
          label="Member Type"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.memberType) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column
          field="memberId"
          label="Member ID"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.memberId) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column
          field="firstName"
          label="First Name"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.firstName) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column
          field="lastName"
          label="Last Name"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.lastName) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column
          field="dateOfBirth"
          label="Date of Birth"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.dateOfBirth) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column sortable field="requestStatus" label="Request Status">
          <template v-slot:header="{ column }">
            {{ column.label }}
          </template>
          <template v-slot="props">
            <span :class="`status-text ${props.row.requestStatus}`">
              {{ getFormattedString(props.row.requestStatus) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column
          label="Actions"
          v-slot="props"
          header-class="has-background-light no-border taller-cell"
          cell-class="no-bottom-border no-right-border center-align taller-cell icons-position"
          width="150"
        >
          <div class="columns icons-position">
            <b-field class="column column-icons icon-left">
              <span
                class="has-text-success"
                data-hover="Info"
                style="cursor: pointer"
                @click="showInfo(props)"
              >
                <img src="../assets/eye.svg" alt="Info" />
              </span>
            </b-field>
            <b-field class="column column-icons">
              <span
                class="has-text-success"
                data-hover="Errors"
                style="cursor: pointer"
                @click="showErrors(props)"
              >
                <img src="../assets/icons/error.svg" alt="Errors" />
              </span>
            </b-field>
            <b-field class="column column-icons icon-right">
              <span
                class="has-text-success"
                data-hover="Edit"
                style="cursor: pointer"
              >
                <router-link
                  v-if="canEdit(props.row.requestStatus)"
                  data-hover="Edit"
                  style="cursor: pointer"
                  :to="{ name: 'TestMembersCreateEdit', params: { generatedUid:  props.row.generatedUid} }"
                >
                  <img src="../assets/edit_button.svg" alt="Edit request" />
                </router-link>
              </span>
            </b-field>
          </div>
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import store from '@/store/store'
import _ from 'lodash'
import iFrame from '@/components/i-frame.vue'
// import Vue from 'vue'

export default {
  components: { iFrame },
  name: 'test-members-list',
  mounted () {
    const tableScrollBody = this.$el.getElementsByClassName('table-wrapper')[0]
    tableScrollBody.addEventListener('scroll', this.onBodyScroll)
  },

  beforeDestroy () {
    /* Clean up just to be sure */
    const tableScrollBody = this.$el.getElementsByClassName('table-wrapper')[0]
    tableScrollBody.removeEventListener('scroll', this.onBodyScroll)
  },
  data () {
    this.getTestMemberRequestsList()

    return {
      testMemberRequestsList: [],
      firstIndex: 0,
      lastIndex: 15,
      showErrorsModal: false,
      showInfoModal: false,
      returnedError: null,
      returnedFullResponse: null
    }
  },

  methods: {
    onBodyScroll: _.debounce(function (event) {
      if (
        event.target.scrollTop + event.target.clientHeight + 500 >=
          event.target.scrollHeight &&
        this.testMemberRequestsList.length >= this.lastIndex
      ) {
        this.lastIndex += 5
      }
    }, 100),

    searchNetworks: _.debounce(function () {
      const lowerSearchCriteria = this.gridSearchCriteria.toLowerCase()
      const storeNetworkConfigList = this.getNetworkConfigListFromStore()

      if (lowerSearchCriteria) {
        this.networkConfigList = storeNetworkConfigList.filter(
          (client) =>
            client.clientName.toLowerCase().includes(lowerSearchCriteria) ||
            client.oeUrlName.toLowerCase().includes(lowerSearchCriteria)
        )
      } else {
        this.networkConfigList = storeNetworkConfigList ?? []
      }

      this.isSearchNetworks = !!lowerSearchCriteria
    }, 400),

    getFormattedString: (value) => value ?? '',

    getTestMemberRequestsList () {
      this.$store
        .dispatch('testMemberRequests/getTestMemberRequests')
        .then(() => {
          this.testMemberRequestsList = this.getTestMemberRequestsListFromStore() ?? []
        })
        .catch(console.error)
    },

    getTestMemberRequestsListFromStore () {
      return store.getters['testMemberRequests/getTestMemberRequestsList']
    },

    onSort (event) {
      if (this.sortedField === event) {
        if (this.isSortDescending) {
          this.sortedField = null

          return
        }

        this.isSortDescending = !this.isSortDescending
      } else this.isSortDescending = false
      this.sortedField = event
    },
    showErrors (props) {
      this.showErrorsModal = !this.showErrorsModal
      this.returnedError = JSON.parse(props.row.returnedError)
    },
    showInfo (props) {
      this.showInfoModal = true
      this.returnedFullResponse = JSON.parse(props.row.returnedFullResponse)
    },
    canEdit (status) {
      return status !== 'MembersCreated'
    }
  }
}
</script>

<style lang="scss" scoped>
.network-list--header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.9rem;
  padding-top: 3.5rem;

  > .control {
    width: 480px;
  }

  > a {
    display: flex;
    align-items: center;
    color: #ec008c;
    font-weight: 600;

    img {
      margin-left: 0.375rem;
    }
  }
}

.network-list--results {
  display: flex;
  font-size: 12px;
  margin-top: -1.275rem;
  margin-bottom: 4px !important;
  color: #000;
  font-weight: 500;
}

.network-list--table {
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 3rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;

  &.is-empty {
    > div.table-wrapper > table {
      min-height: 37.5rem;
    }
  }

  > div.table-wrapper > table {

    th {
      border: none;
      background: #EBEBEB !important;
      font-weight: 600;
      padding: 0.75rem;

      span {
        color: #444;
      }

      svg {
        color: #7A7E81;
        margin-left: 0.3rem;
      }
    }

    td {
      padding: calc((3.75rem - 2rem) / 2) 0.75rem;
      text-align: left;
      vertical-align: middle;

      .column-icons {
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      span {
        height: min-content;
        color: #444;
      }
    }
  }

  .status-text {
    &.Published {
      color: #3BB02E;
    }

    &.N\/A {
      color: #7A7E81;
    }

    &.Unpublished {
      color: #444;
    }
  }
}
</style>
