<script>

import Vue from 'vue'

export default {
  render (h) {
    return h('iframe', {
      on: { load: this.renderChildren }
    })
  },
  beforeUpdate () {
    this.iApp.children = Object.freeze(this.$slots.default)
  },
  methods: {
    renderChildren () {
      const children = this.$slots.default
      const body = this.$el.contentDocument.body
      body.style.margin = '0'
      body.style.padding = '0'
      body.style.fontFamily = 'Centivo SemiBold, sans-serif'
      const el = document.createElement('div')
      body.appendChild(el)

      const iApp = new Vue({
        name: 'iApp',
        // freezing to prevent unnecessary Reactifiation of vNodes
        data: { children: Object.freeze(children) },
        parent: this,
        render (h) {
          return h('div', this.children)
        }
      })

      iApp.$mount(el) // mount into iframe
      this.iApp = iApp // cache instance for later updates
    }
  }
}

</script>
